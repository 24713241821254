<template>
    <v-container style="padding: 16px 32px">
        <v-card
                class="mx-auto"
                outlined
        >
            <v-card-title class="headline">Przekształcanie sadzy:</v-card-title>

            <v-card-subtitle style="color: #FFFFFF;padding-left: 22px">
                <v-container fluid>
                    <v-row>
                        <v-col cols="12" md="5" lg="3">
                            <v-img class="defaultImageStyle" src="../assets/carbon/Carbon_Pyrolysis.jpg" alt="Produkt pirolizy - sadza" width="auto" contain style="elevation: 4"></v-img>
                        </v-col>
                        <v-col cols="12" md="7" lg="9">
                            <p class="defaultTextStyle">
                                Zarówno podczas zamówienia, jak i podczas pracy instalacji do pirolizy opon inwestor może zdecydować się na
                                zwiększenie możliwości obróbki produktów końcowych poprzez dołączenie linii służącej do przekształcenia sadzy poprocesowej na sadzę techniczną.
                                Taki zabieg znacząco przekłada się na zyski, gdyż tona sadzy poprocesowej kosztuje ok. 300zł,
                                natomiast tona sadzy technicznej nierozfrakcjonowanej kosztuje ok. 1500zł
                            </p>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <p class="defaultTextStyle" style="text-align: right">
                                Cena dodatkowej linii technologicznej to ok. 4 mln zł.
                            </p>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-subtitle>
        </v-card>
    </v-container>
</template>
<script>
export default {
  inheritAttrs: false,
  name: 'carbon',
  props: [],
  data: function () {
    return {
    }
  },
  mounted: function () {
  },
  computed: {
  },
  methods: {
  }
}
</script>
<style scoped>

</style>
